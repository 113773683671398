.modal-container {
    z-index: 9998 !important;
}

.grid_col-3:nth-child(n) {
    margin-right: 0px;
}

.close-window-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
}

.small .hero .hero-title {
    font-size: 50px;
}

.medium .hero .hero-title {
    font-size: 55px;
}

.big .hero .hero-title {
    font-size: 70px;
}

.small .hero .hero-excerpt p {
    font-size: 16px;
}

.medium .hero .hero-excerpt p {
    font-size: 18px;
}

.big .hero .hero-excerpt p {
    font-size: 20px;
}

.small .hero-subtitle {
    font-size: 16px;
}

.medium .hero-subtitle {
    font-size: 18px;
}

.big .hero-subtitle {
    font-size: 22px;
}

.small h1 {
    font-size: 1em;
}

.medium h1 {
    font-size: 2em;
}

.big h1 {
    font-size: 3em;
}

.small #hero_button {
    font-size: 1em;
}

.medium #hero_button {
    font-size: 1em;
}

.big #hero_button {
    font-size: 1em;
}

.small .slider.rootCategory .section-title {
    font-size: 22px;
}

.medium .slider.rootCategory .section-title {
    font-size: 24px;
}

.big .slider.rootCategory .section-title {
    font-size: 27px;
}

.small .slider.rootCategory .rootCategory-close-info {
    font-size: 18px;
}

.medium .slider.rootCategory .rootCategory-close-info {
    font-size: 20px;
}

.big .slider.rootCategory .rootCategory-close-info {
    font-size: 23px;
}

.small .slider.rootCategory .rootCategory-category-name {
    font-size: 18px;
}

.medium .slider.rootCategory .rootCategory-category-name {
    font-size: 20px;
}

.big .slider.rootCategory .rootCategory-category-name {
    font-size: 23px;
}

.small .section-title {
    font-size: 32px;
}

.medium .section-title {
    font-size: 34px;
}

.big .section-title {
    font-size: 37px;
}

.small .text-content-title-medium {
    font-size: 30px;
}

.medium .text-content-title-medium {
    font-size: 32px;
}

.big .text-content-title-medium {
    font-size: 35px;
}

.small .text-content-description {
    font-size: 15px;
}

.medium .text-content-description {
    font-size: 17px;
}

.big .text-content-description {
    font-size: 20px;
}

.small .text-content-price {
    font-size: 17px;
}

.medium .text-content-price {
    font-size: 22px;
}

.big .text-content-price {
    font-size: 22px;
}

.small .modal-container .modal-wrapper .modal-title {
    font-size: 38px;
}

.medium .modal-container .modal-wrapper .modal-title {
    font-size: 40px;
}

.big .modal-container .modal-wrapper .modal-title {
    font-size: 44px;
}

.small .modal-container .modal-wrapper .modal-text-content {
    font-size: 22px;
}

.medium .modal-container .modal-wrapper .modal-text-content {
    font-size: 25px;
}

.big .modal-container .modal-wrapper .modal-text-content {
    font-size: 28px;
}

.small .modal-container .modal-wrapper .item-price {
    font-size: 24px;
}

.medium .modal-container .modal-wrapper .item-price {
    font-size: 26px;
}

.big .modal-container .modal-wrapper .item-price {
    font-size: 29px;
}

.small .modal-container .modal-wrapper .orders-spacing {
    font-size: 24px;
}

.medium .modal-container .modal-wrapper .orders-spacing {
    font-size: 26px;
}

.big .modal-container .modal-wrapper .orders-spacing {
    font-size: 29px;
}

.small .modal-container .modal-wrapper .orders-spacing h4 {
    font-size: 20px;
}

.medium .modal-container .modal-wrapper .orders-spacing h4 {
    font-size: 22px;
}

.big .modal-container .modal-wrapper .orders-spacing h4 {
    font-size: 25px;
}

.small .modal-container .modal-wrapper .orders-spacing .optional {
    font-size: 11px;
}

.medium .modal-container .modal-wrapper .orders-spacing .optional {
    font-size: 13px;
}

.big .modal-container .modal-wrapper .orders-spacing .optional {
    font-size: 16px;
}

.small .modal-container .modal-wrapper .checkbox-container .dressing,
.checkbox-container .side-dish {
    font-size: 20px;
}

.medium .modal-container .modal-wrapper .checkbox-container .dressing,
.checkbox-container .side-dish {
    font-size: 22px;
}

.big .modal-container .modal-wrapper .checkbox-container .dressing,
.checkbox-container .side-dish {
    font-size: 25px;
}

.small .modal-container .modal-wrapper .price {
    font-size: 20px;
}

.medium .modal-container .modal-wrapper .price {
    font-size: 22px;
}

.big .modal-container .modal-wrapper .price {
    font-size: 25px;
}

.small .modal-container .modal-wrapper .xs_title {
    font-size: 23px;
}

.medium .modal-container .modal-wrapper .xs_title {
    font-size: 25px;
}

.big .modal-container .modal-wrapper .xs_title {
    font-size: 28px;
}

.small .modal-container .modal-wrapper .order-place {
    font-size: 20px;
}

.medium .modal-container .modal-wrapper .order-place {
    font-size: 22px;
}

.big .modal-container .modal-wrapper .order-place {
    font-size: 25px;
}

.small .modal-container .modal-wrapper .items-review {
    font-size: 20px;
}

.medium .modal-container .modal-wrapper .items-review {
    font-size: 18px;
}

.big .modal-container .modal-wrapper .items-review {
    font-size: 25px;
}

.small .modal-container .modal-wrapper .items-review .upsales-btn {
    font-size: 14px;
}

.medium .modal-container .modal-wrapper .items-review .upsales-btn {
    font-size: 15px;
}

.big .modal-container .modal-wrapper .items-review .upsales-btn {
    font-size: 15px;
}

.small .key-value {
    font-size: 22px;
}

.medium .key-value {
    font-size: 24px;
}
.medium .key-value .sub-key {
    font-size: 20px;
}

.big .key-value {
    font-size: 25px;
}

.small .deliver-option {
    font-size: 20px;
}

.medium .deliver-option {
    font-size: 22px;
}

.big .deliver-option {
    font-size: 25px;
}

.small .checkbox-container .dressing,
.checkbox-container .side-dish {
    font-size: 20px;
}

.medium .checkbox-container .dressing,
.checkbox-container .side-dish {
    font-size: 22px;
}

.big .checkbox-container .dressing,
.checkbox-container .side-dish {
    font-size: 25px;
}

.small .select.default-select {
    font-size: 18px;
}

.medium .select.default-select {
    font-size: 20px;
}

.big .select.default-select {
    font-size: 23px;
}

.rootCategory-category-name {
    text-align: center;
}

.deliver-options:hover .arrow-right {
    margin-right: 0px;
}
